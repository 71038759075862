<template>
    <div>
        <div style="background-color: #ffffff;">
            <div style="background-color: #ffffff;margin: 0 auto;" :style="{width: $oucy.pageWidth+'px'}">
                <div class="pageCenter titleBar" :style="{width: $oucy.pageWidth+'px'}">
                    <div class="titleBar_left">
                        <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                        <div class="titleBar_left_txt">
                            发布求购
                        </div>
                    </div>
                    <div class="titleBar_right" @click="$oucy.replace('/')">
                        返回首页
                    </div>
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :style="{width: $oucy.pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="(item,index) in levelList" :key="index" :to="item.path">{{item.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="box" style="background-color: #ffffff;">
            <div :style="{width: $oucy.pageWidth+'px'}" style="margin: 0 auto;">
                <div class="p-t-50 bold m-b-20 text-center" style="font-size: 30px">{{id?'编辑':'发布'}}求购信息</div>
                <el-form ref="form" :model="form" label-width="120px">
                    <div class="title bold m-b-20">基本信息</div>
                    <el-form-item label="产品标题" required>
                        <el-input v-model="form.askTitle" maxlength="20" show-word-limit placeholder="请输入求购产品名称或产品相关标题"></el-input>
                    </el-form-item>
                    <el-form-item label="求购类型" required>
    <!--                     <el-select v-model="form.categoryId" placeholder="请选择转载类型" style="width: 100%;" @change="getBuyAskCategoryFormByCategoryId">
                            <el-option v-for="item in buyAskCategory" :key="item.id" :label="item.categoryName" :value="item.id">
                            </el-option>
                        </el-select> -->
                        <el-button v-for="item in buyAskCategory" :key="item.id" size="mini" :type="form.categoryId==item.id?'primary':''" @click="form.categoryId=item.id,getBuyAskCategoryFormByCategoryId()">{{item.categoryName}}</el-button>
                        <!-- <el-button type="primary">主要按钮</el-button> -->
                    </el-form-item>
                    <!-- :prop="v.formRequired?v.value:null" -->
                    <!-- 表单类型 0文本框text 1开关 2文本域textarea 3 单项选择select 4日期date 5地区 6定位位置 7上传视频 8标题（可用于搜索） 9外部链接 -->
                    <template v-for="(v,i) in buyAskCategoryForm">
                        <el-form-item v-if='v.formType == 0 || v.formType == 8 || v.formType == 9' :label="v.formTitle" :required="v.formRequired">
                            <el-input v-model="v.value" :placeholder="'请输入'+v.formTitle"></el-input>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 1' :label="v.formTitle" :required="v.formRequired">
                            <el-switch v-model="v.value" active-value="1" inactive-value="0"></el-switch>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 2' :label="v.formTitle" :required="v.formRequired">
                            <el-input v-model="v.value" maxlength="200" type="textarea" :rows="4" show-word-limit :placeholder="'请输入'+v.formTitle"></el-input>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 3' :label="v.formTitle" :required="v.formRequired">
                            <el-select v-model="v.value" placeholder="请选择">
                                <el-option v-for="item in v.options" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 4' :label="v.formTitle" :required="v.formRequired">
                            <el-date-picker v-model="v.value" type="date" placeholder="选择日期" format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 5' :label="v.formTitle" :required="v.formRequired">
                            <el-cascader v-model="v.value" style="width: 100%" :props="cityProps" :placeholder="'请选择'+v.formTitle"></el-cascader>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 6' :label="v.formTitle" :required="v.formRequired">
                            <el-input v-model="v.value.addr" type="text" :rows="2" autocomplete="off" :placeholder="'请选择'+v.formTitle" @focus="v.value && v.value.latitude?'':onAddrSelect(v,'value')"/>
                            <el-button @click="onAddrSelect(v,'value')" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item v-if='v.formType == 7' :label="v.formTitle" :required="v.formRequired">
                            <div class="df" @click="del(v.value)">
                                <MyMedia :srcList="v.value" v-if="v.value" :isShowDel="true" @clickDel="clickDel" />
                                <upload @uploadBack="uploadBack"><i class="el-icon-plus avatar-uploader-icon uploader" @click="setUploadOpject(v)"></i></upload>
                            </div>
                            <div class="c-2">规格建议：1.文件格式：图片/视频;2.图片大小每张不超过3M;3.视频大小100M以内</div>
                        </el-form-item>
                    </template>
                    <el-form-item label="信息标签">
                        <!-- form.tags -->
                        <div>
                            <span class="btn c-1" @click="v.select=!v.select" :class="{'select':v.select}" v-for="(v,i) of buyAskTag" :key="i">{{v.tagName}}
                                <img src="@/assets/images/stockCar/corner.png" class="corner">
                            </span>
                        </div>
                    </el-form-item>
                    <div class="title m-b-20 bold">
                        <div>联系人信息</div>
                    </div>
                    <el-form-item label="匿名发布">
                        <el-checkbox v-model="form.askAnonymityStatus">是否匿名发布</el-checkbox>
                    </el-form-item>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="联系人" required>
                                <el-input v-model="form.askContactName" placeholder="请输入联系人姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="联系电话" required>
                                <el-input v-model="form.askContactPhone" placeholder="请输入联系人电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
 
                    <el-form-item label="求购位置" required>
                        <el-input v-model="form.askContactPositionName" type="textarea" :rows="2" autocomplete="off" placeholder="填写求购位置" @focus="form.askContactPositionLatitude?'':onAddrSelect(form,'form')"/>
                        <el-button @click="onAddrSelect(form,'form')" icon="el-icon-location" type="text">选择</el-button>
                    </el-form-item>
                    <el-form-item label="预交款" prop="schemeName" v-if="0">
                        <el-checkbox v-model="checked">是否预交款</el-checkbox>
                        <div v-if="checked">
                            <span class="btn" :class="{'select':1==1}">100
                                <img src="@/assets/images/stockCar/corner.png" class="corner">
                            </span>
                            <span class="btn">200
                                <img src="@/assets/images/stockCar/corner.png" class="corner">
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="支付方式" prop="schemeName" v-if="checked">
                        <div>
                            <span class="btn m-r-10" :class="{'select':1==1}">
                                <img src="@/assets/images/stockCar/WeChat.png" class="btnImg">
                                微信
                                <img src="@/assets/images/stockCar/corner.png" class="corner">
                            </span>
                            <span class="btn">
                                <img src="@/assets/images/stockCar/Alipay.png" class="btnImg">
                                支付宝
                                <img src="@/assets/images/stockCar/corner.png" class="corner">
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div>
                            <el-checkbox v-model="form.checkedTiaokuan">阅读并同意</el-checkbox><span class="relevantClause pointer" @click="$oucy.openNewPage('/AfficheDetail?id=4f9abb2e18a441e98010592763006888&type=1')">《相关条款》</span>
                        </div>
                        <el-button type="primary" @click="onSubmit" :disabled="!form.checkedTiaokuan">立即发布</el-button>
                        <!-- <el-button>取消</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- 位置组件 -->
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import addr from "@/components/Addr";
import { buyAskCategory, certificationFile, buyAskTag, buyAsk } from "@/service"
import { localSet, localGet, localDel } from "@/store/store";

export default {
    name: "ReleaseCenter",
    components: {
        addr,
    },
    data() {
        return {
            levelList: [],

            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },
            form: {
                checkedTiaokuan: true, //同意阅读
                askContactName: null, //    联系人姓名       true
                askContactPhone: null, //   联系人电话       true    
                askContactPositionName: null, //    求购位置 - 中文       true
                askTitle: null, //  标题      true    
                categoryId: null, //    求购类型编码      true
                askAnonymityStatus: false, //    是否匿名发布      true
                askContactPositionLatitude: null, //    求购位置 - 经度       false   
                askContactPositionLongtitude: null, //  求购位置 - 纬度       false   
                askPrepayFee: null, //  是否显示，若关闭，其余字段均无效预交款，单位：元        false
                formValues: null, //    表单值     false   
                tags: null, //  关联标签        false
            },
            // 位置组件
            addrSelectVisible: false,
            checked: false,
            // 求购信息标签
            buyAskTag: [],
            // 求购标签
            buyAskCategory: [],
            // 求购表单
            buyAskCategoryForm: [],
            uploadOpject: {},
            addrSelectObject: {}, //位置对象
            addrSelectType: null, //位置类型
            delValue: null,
            detail: null,
            id: null,
        }

    },
    mounted() {
        this.id = this.$route.query.id
        this.getAllBuyAskCategory()
        if (this.id) {
            this.getBuyAskById()
            this.levelList = [
                { path: '/', meta: { title: '首页' } },
                { path: '/acc/userinfo', meta: { title: '个人中心' } },
                { path: '/ReleaseCenterAskBuyManagement', meta: { title: '求购管理' } },
                { meta: { title: '编辑求购' } }
            ]
        } else {
            let userInfo = localGet('loginUser') || '{}'
            this.form.askContactName = userInfo.userDcodeUsername
            this.form.askContactPhone = userInfo.userPhoneNumber
            this.levelList = [
                { path: '/', meta: { title: '首页' } },
                { path: '/ReleaseCenter', meta: { title: '发布中心' } },
                { meta: { title: '发布求购' } }
            ]
        }

        //监听登录事件
        this.eventBus/*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/.$on(this.$oucy.msgKeys.LOGIN_SUCCESS,(res)=>{
            this.getAllBuyAskCategory()
        });

    },
    watch: {
        /*        $route: {
                    handler(to, from) {
                        this.getBreadcrumb()
                    },
                    deep: true,
                    immediate: true
                }*/
    },
    methods: {
        // 面包屑
        getBreadcrumb() {
            //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
            let matched = this.$route.matched.filter(item => item.name)
            const first = matched[0];
            if (first && first.name !== '首页') {
                matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
            }
            this.levelList = matched
        },
        // 设置上传
        setUploadOpject(v) {
            this.uploadOpject = v
        },
        //查询所有买家求购信息标签
        getAllBuyAskTag() {
            buyAskTag.getAllBuyAskTag({}).then(res => {
                let ids = []
                if (this.id && this.detail) {
                    for (let v of this.detail.buyAskTags) {
                        ids.push(v.id)
                    }
                }
                for (let v of res) {
                    v.select = ids.indexOf(v.id) > -1
                }
                this.buyAskTag = res
            })
        },
        // 获取求购标签
        getAllBuyAskCategory() {
            buyAskCategory.getAllBuyAskCategory({}).then(res => {
                if (res && res.length) {
                    this.buyAskCategory = res
                    // 不是编辑的查一个标签的数据
                    if (!this.id) {
                        this.form.categoryId = res[0].id
                        this.getBuyAskCategoryFormByCategoryId()
                        this.getAllBuyAskTag()
                    }
                }
            })
        },
        // 根据求购类型编码查询求购类型表单
        getBuyAskCategoryFormByCategoryId() {
            certificationFile.getBuyAskCategoryFormByCategoryId({ categoryId: this.form.categoryId }).then(res => {
                for (let v of res) {
                    v.value = null
                    if (v.formType == 1) {
                        v.value = v.formDefaultValue == 1 ? true : false
                    } else
                    if (v.formType == 6) {
                        v.value = {}
                    } else
                    if (v.formType == 6) {
                        v.value = []
                    } else
                    if (v.formType == 3) {
                        v.options = this.getOptions(v)
                    }
                }
                this.buyAskCategoryForm = res
            })
        },
        // 获取表单 select内的option
        getOptions(v) {
            // trim方法用于删除字符串的头尾空白符,空白符包括:空格、制表符 tab、换行符等其他空白符等
            let str = v.formDefaultValue.trim()
            let arr = [],
                options = []
            if (str.indexOf(";") >= 0) {
                arr = str.split(";");
            }
            for (let vv of arr) {
                if (vv) {
                    let ar = vv.split('=')
                    options.push({
                        label: ar[0],
                        value: ar[1],
                    })
                }
            }
            return options

        },
        // 上传视频 图片
        uploadBack(v) {
            if (!this.uploadOpject.value) {
                this.uploadOpject.value = []
            }
            this.uploadOpject.value.push(v)
        },
        // 点删除
        clickDel(obj) {
            setTimeout(() => {
                this.delValue.splice(obj.i, 1)
            }, 100)
        },
        // 删除的对象
        del(v) {
            this.delValue = v
        },
        // 点位置
        onAddrSelect: function(v, type) {
            this.addrSelectObject = v
            this.addrSelectType = type
            this.addrSelectVisible = true;
        },
        // 位置回调
        addrSelectHandle: function(addr) {
            this.addrSelectVisible = false;
            if (this.addrSelectType == 'value') {
                // this.addrSelectObject.value={}
                this.addrSelectObject.value.latitude = addr.location.lat
                this.addrSelectObject.value.longtitude = addr.location.lng
                this.addrSelectObject.value.addr = addr.address + '(' + addr.name + ')';
            } else {
                this.addrSelectObject.askContactPositionLatitude = addr.location.lat
                this.addrSelectObject.askContactPositionLongtitude = addr.location.lng
                this.addrSelectObject.askContactPositionName = addr.address + '(' + addr.name + ')';
            }
        },
        // 获取标签
        getTags() {
            let tags = []
            for (let v of this.buyAskTag) {
                if (v.select) tags.push(v.id)
            }
            return tags
        },
        // 获取提交表单数据
        getFormValues() {
            let formValues = []
            // 表单类型 0文本框text 1开关 2文本域textarea 3 单项选择select 4日期date 5地区 6定位位置 7上传视频 8标题（可用于搜索） 9外部链接 
            for (let v of this.buyAskCategoryForm) {
                if (v.formType == 4 && v.value) {
                    formValues.push({ key: v.id, value: new Date(v.value).format('yyyy-MM-dd') })
                } else
                if (v.formType == 5 && v.value && v.value.length) {
                    formValues.push({ key: v.id, value: v.value[v.value.length - 1] })
                } else
                    /*                if (v.formType == 6 && v.value && Object.keys(v.value).length) {
                                        formValues.push({
                                            key: v.id,
                                            value: {
                                                addr: v.value.askContactPositionName,
                                                latitude: v.value.askContactPositionLatitude,
                                                longtitude: v.value.askContactPositionLongtitude,
                                            }
                                        })
                                    } else*/
                    if (v.formType == 7) {
                        if (v.value && v.value.length) {
                            let value = []
                            for (let vv of v.value) {
                                value.push({
                                    value: vv,
                                    type: this.$oucy.getType(vv)
                                })
                            }
                            formValues.push({ key: v.id, value: value })
                        }
                        // continue
                    } else
                if (v.value) {
                    formValues.push({ key: v.id, value: v.value })
                }
            }
            return formValues
        },
        // 点提交
        onSubmit() {
            if (!this.form.askTitle) {
                this.$alert('产品标题不能为空', '提示')
                return
            } else
            if (!this.form.categoryId) {
                this.$alert('求购类型不能为空', '提示')
                return
            }
            let message = null
            for (let v of this.buyAskCategoryForm) {
                if(v.formRequired){
                    
                    if (v.formType == 6 && (!v.value || Object.keys(v.value).length == 0)) {
                        message = v.formTitle
                        break
                    }
                    if (v.formType == 7 && (!v.value || v.value.length == 0)) {
                        message = v.formTitle
                        break
                    }
                    if (v.formRequired && !v.value) {
                        message = v.formTitle
                        break
                    }
                }
            }
            if (message) {
                this.$alert(message + '不能为空', '提示')
                return
            }
            if (!this.form.askContactName) {
                this.$alert('联系人不能为空', '提示')
                return
            } else
            if (!this.form.askContactPhone) {
                this.$alert('联系电话不能为空', '提示')
                return
            } else
            if (!this.form.askContactPositionName) {
                this.$alert('求购位置不能为空', '提示')
                return
            }

            let formValues = this.getFormValues()
            this.form.formValues = formValues.length ? JSON.stringify(formValues) : null
            this.form.formValuesJson = formValues

            let tags = this.getTags()
            this.form.tags = tags.length ? JSON.stringify(tags) : null
            this.form.tagsJson = tags
            console.log(this.form)
            // 是编辑
            if (this.id) {
                this.keywordFiltr('updateBuyAsk')
                console.log(1)
            } else {
                this.keywordFiltr('newBuyAsk')
                console.log(2)
            }
        },
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.form).then(res=>{
               this[fn]() 
            },err=>{
                console.log(err)
            })
        },
        // 发布求购
        newBuyAsk() {
            buyAsk.newBuyAsk(this.form).then(res => {
                this.$message('发布成功')
                this.$oucy.go('/askBuyManagement')
            })
        },
        // 修改求购
        updateBuyAsk() {
            buyAsk.updateBuyAsk(this.form).then(res => {
                this.$message('发布成功')
                this.$oucy.go('/askBuyManagement')
            })
        },
        // 请求求购详情
        getBuyAskById() {
            buyAsk.getBuyAskById({ id: this.id }).then(res => {
                this.setbuyAskCategoryForm(res)
                this.detail = res
                this.form.id = this.id
                this.form.askContactName = res.askContactName
                this.form.askContactPhone = res.askContactPhone
                this.form.askContactPositionName = res.askContactPositionName
                this.form.askTitle = res.askTitle
                this.form.categoryId = res.ltBuyAskCategoryDto.id
                this.form.askAnonymityStatus = res.askAnonymityStatus ? true : false
                this.form.askContactPositionLatitude = res.askContactPositionLatitude
                this.form.askContactPositionLongtitude = res.askContactPositionLongtitude
                this.form.askPrepayFee = res.askPrepayFee
                this.getAllBuyAskTag()
            })
        },
        // 设置回填表单
        setbuyAskCategoryForm(res) {
            let buyAskCategoryForm = []
            let data = res.buyAskForms
            for (let v of data) {
                // 表单类型 0文本框text 1开关 2文本域textarea 3 单项选择select 4日期date 5地区 6定位位置 7上传视频 8标题（可用于搜索） 9外部链接 

                if (v.buyAskCategoryForm && v.buyAskCategoryForm.formType == 3) {
                    v.buyAskCategoryForm.options = this.getOptions(v.buyAskCategoryForm)
                    v.buyAskCategoryForm.value = v.value
                    buyAskCategoryForm.push(v.buyAskCategoryForm)
                } else
                if (v.buyAskCategoryForm && v.buyAskCategoryForm.formType == 5) {
                    v.buyAskCategoryForm.value = v.chinaDto.idPath // JSON.parse(v.value)
                    buyAskCategoryForm.push(v.buyAskCategoryForm)
                } else
                if (v.buyAskCategoryForm && v.buyAskCategoryForm.formType == 6) {
                    v.buyAskCategoryForm.value = JSON.parse(v.value)
                    buyAskCategoryForm.push(v.buyAskCategoryForm)
                } else
                if (v.buyAskCategoryForm && v.buyAskCategoryForm.formType == 7) {
                    let value = []
                    let arr = JSON.parse(v.value)
                    for (let vv of arr) {
                        value.push(vv.value)
                    }
                    v.buyAskCategoryForm.value = value
                    buyAskCategoryForm.push(v.buyAskCategoryForm)
                } else {
                    v.buyAskCategoryForm.value = v.value
                    buyAskCategoryForm.push(v.buyAskCategoryForm)
                }
            }
            this.merge(res.buyAskCategoryForms, buyAskCategoryForm)
            this.buyAskCategoryForm = res.buyAskCategoryForms
        },
        // 合并对象
        merge(A, B) {
            for (let v of A) {
                for (let vv of B) {
                    if (v.id == vv.id) {
                        v = Object.assign(v, vv)
                    }
                }
            }
        },


    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    text-align: left;
    background: #FAFAFA;
    padding: 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}

.box {
    text-align: left;
    padding-bottom: 30px;
}

.relevantClause {
    font-weight: 400;
    color: #2090FF;
}

.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}

.uploader {
    width: 100px;
    height: 100px;
    line-height: 100px;
    display: inline-block;
    border: 1px dashed #ddd;
}

/deep/ .el-form-item__label {
    font-weight: bold;
}
</style>